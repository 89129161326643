<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="放映时间">
						<a-range-picker v-model:value="time"
							:showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }" />
					</a-form-item>

					<a-row v-show="showAll">
						<a-form-item label="会员昵称" name="nickname" class="ui-form__item">
							<a-input v-model:value="formState.nickname" placeholder="请输入会员昵称"></a-input>
						</a-form-item>

						<a-form-item label="会员手机" name="phone" class="ui-form__item">
							<a-input v-model:value="formState.phone" placeholder="请输入会员手机"></a-input>
						</a-form-item>

						<a-form-item label="商品sku" name="goodsSku" class="ui-form__item">
							<a-input v-model:value="formState.goodsSku" placeholder="请输入商品sku"></a-input>
						</a-form-item>

						<a-form-item label="券号" name="barcode" class="ui-form__item">
							<a-input v-model:value="formState.barcode" placeholder="请输入券号"></a-input>
						</a-form-item>

						<a-form-item label="订单编号" name="orderNo" class="ui-form__item">
							<a-input v-model:value="formState.orderNo" placeholder="请输入订单编号"></a-input>
						</a-form-item>

						<a-form-item class="ui-form__item" label="购票时间">
							<a-range-picker v-model:value="payTime"
								:showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }"
								:disabledDate="disabledDate" />
						</a-form-item>
					</a-row>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['coupon_tiktok_sale_export']">
							<exportComponent type="tikTokTicketConsume" :searchData="searchData"></exportComponent>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="orderId" :pagination="pagination" :columns="columns" :dataSource="list"
					:scroll="{ x: 2700 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'card'">
							<div>
								<div style="border: solid 1px #eee;border-radius: 8px;padding: 6px 10px;"
									v-for="item in record.tikTokTicketOrderInfoVOS" :key="item.barcode">
									<p style="padding: 0;margin: 0;">抖音券号：{{ item.barcode }}</p>
									<p style="padding: 0;margin: 0;">抖音券名称：{{ item.title }}</p>
									<p style="padding: 0;margin: 0;">抖音商品sku：{{ item.goodsSku }}</p>
								</div>
							</div>
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>会员昵称：{{ record.userName || '-' }}</div>
							<div>会员手机：{{ record.userPhone || '-' }}</div>
						</template>
						<template v-if="column.key === 'seat'">
							<span v-for="(item, i) in record.cinemaSeatsJsonVOS" :key="i">
								<a-tag style="margin-bottom: 6px;" color="blue" v-if="item">
									{{ item.rowId }}排 {{ item.colId }}座
								</a-tag>
							</span>
						</template>
						<template v-if="column.key === 'status'">
							<div>{{ transStatus(record.status) }}</div>
						</template>
						<template v-if="column.key === 'showTime'">
							<div>{{ transDateTime(record.showStartTime) }}</div>
							至
							<div>{{ transDateTime(record.showEndTime) }}</div>
						</template>
						<template v-if="column.key === 'ticketStatus'">
							{{ transTicketStatus(record.ticketStatus) }}
							<div v-if="record.ticketStatus === 3">
								{{ transDateTime(record.printTime) }}
							</div>
							<div v-if="record.ticketStatus === 4">
								{{ transDateTime(record.refundTime) }}
							</div>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small" @click="onDetail(record)">详情</a-button>
							<a-button v-if="record.status !== 'AFTERSALE_FINISH'" type="link" size="small" v-permission="['coupon_tiktok_sale_refund']" @click="onRefund(record)">申请售后</a-button>
						</template>
					</template>

					<template #summary v-if="list && list.length">
						<a-table-summary-row>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ total.seatNumSum || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ total.diffPriceSum || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
						</a-table-summary-row>
					</template>
				</a-table>
			</div>
		</a-spin>

		<a-modal v-model:visible="refundVisible" title="退款" @cancel="onRefundCancel" @ok="onRefundOk">
			<a-spin :spinning="loading">
				<a-form ref="refundForm" name="refundForm" :model="refundData">
					<a-form-item class="ui-refundForm__item" label="退款金额">
						{{ detail.payment }}元
					</a-form-item>
					
					<div class="ui-warn__box">
						<div class="ui-warn">
							抖音下单1个小时内，售后撤销核销抖音券；
						</div>
						<div class="ui-warn">
							抖音下单1个小时后，抖音券无法在线退款，售后只能退补差金额；
						</div>
					</div>

					<a-form-item label="退款备注" name="remark" :rules="[{required: true, message: '请输入退款备注'}]">
						<a-textarea v-model:value="refundData.remark" placeholder="请输入退款备注"></a-textarea>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
	<div v-if="showModal">
		<detail :id="id" @back="onBack"></detail>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import detail from './detail.vue';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import {
		getTikTokSaleList
	} from '@/service/modules/coupon.js';
	import { ticketOrderRefund } from '@/service/modules/report.js';
	export default {
		components: {
			Icon,
			exportComponent,
			detail
		},
		data() {
			return {
				loading: false,
				showAll: false,
				refundVisible: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					// couponType: 1,
					//organizationId: 0,
					cinemaId: 0,
				},
				searchData: {},
				id: 0,
				time: [],
				payTime: [],
				showModal: false,
				modelRef: {},
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
					width: 190
				}, {
					title: '券信息',
					key: "card"
				}, {
					title: '会员信息',
					key: 'userInfo',
					width: 230
				}, {
					title: '影片名称',
					dataIndex: 'filmName'
				}, {
					title: '座位信息',
					key: 'seat',
				}, {
					title: '座位数量',
					dataIndex: 'seatNum',
					width: 100
				}, {
					title: '补差金额',
					dataIndex: 'diffPrice',
					width: 100
				}, {
					title: '交易状态',
					key: 'status',
					width: 100
				}, {
					title: '放映起始/结束时间',
					width: 200,
					key: 'showTime'
				}, {
					title: '取票状态',
					key: 'ticketStatus',
					width: 250
				}, {
					title: '交易时间',
					key: 'createTime',
					width: 200
				}, {
					title: '操作',
					fixed: 'right',
					width: 100,
					align: 'center',
					key: 'action'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				total: {},
				refundData: {}
			}
		},
		created() {
			this.getOrganizationList();
			//this.onSearch();
		},
		methods: {
			transTicketStatus(val) {
				if (val === 1) {
					return '未取票'
				}
				if (val === 3) {
					return '已取票'
				}
				if (val === 4) {
					return '已退票'
				}
			},
			transStatus(val, type) {
				if (val === 'WAIT_BUYER_PAY') {
					return '待支付';
				}
				if (val === 'SYSTEM_CANCEL') {
					return '系统超时取消';
				}
				if (val === 'CUSTOMER_CANCEL') {
					return '用户自行取消';
				}
				if (val === 'WAIT_SELLER_SEND_GOODS') {
					return '待发货'
				}
				if (val === 'WAIT_CHECK') {
					return '待核销'
				}
				if (val === 'TRADE_FINISH') {
					if (type === 2) {
						return '已核销'
					} else {
						return '订单完成'
					}
				}
				if (val === 'WAIT_BUYER_CONFIRM_GOODS') {
					return '待收货'
				}
				if (val === 'AFTERSALE_FINISH') {
					return '交易关闭'
				}
				if (val === 'CHECKOUT_SUCCESS') {
					return '已下单'
				}
			},
			onBack() {
				this.showModal = false;
			},
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId :
					undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				if (this.time && this.time.length) {
					this.searchData.startTime = this.moment(this.time[0]).unix();
					this.searchData.endTime = this.moment(this.time[1]).unix();
				}
				if (this.payTime && this.payTime.length) {
					this.searchData.startPayTime = this.moment(this.payTime[0]).unix();
					this.searchData.endPayTime = this.moment(this.payTime[1]).unix();
				}
				this.getData();
			},
			reset() {
				this.time = [];
				this.payTime = [];
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getTikTokSaleList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.total = ret.data.list[0];
						this.list = ret.data.list.splice(1);
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onRefund(item) {
				this.detail = item;
				this.refundData = {
					refund: 0
				};
				this.refundVisible = true;
			},
			onRefundCancel() {
				this.$refs.refundForm.resetFields();
				this.refundVisible = false;
				this.detail = {};
				this.refundData = {
					refund: 0
				};
			},
			onRefundOk() {
				this.$refs.refundForm.validateFields().then(() => {
					this.$confirm({
						title: '提示',
						content: '确定需要售后吗？',
						onOk: async () => {
							this.loading = true;
							try {
								let ret = await ticketOrderRefund({
									orderId: this.detail.orderId,
									refund: this.detail.diffPrice,
									description: this.refundData.remark
								});
								this.loading = false;
								if (ret.code === 200) {
									this.$message.success('退票操作成功');
									this.refundVisible = false;
									this.getData();
								}
							} catch (e) {
								this.loading = false;
								console.log(e)
							}
						}
					})
				});
			},
			onDetail(item) {
				this.id = item.orderId;
				this.showModal = true;
			},
			disabledDate(current) {
				return current && current >= this.moment().endOf('day');
			}
		}
	}
</script>

<style>
	.ui-refundForm__item {
		margin-bottom: 0px;
	}
</style>
<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-warn__box {
		margin: 10px 0 20px 0;
		padding: 10px 20px;
		background-color: antiquewhite;
		border-radius: 10px;
	}
	.ui-warn {
		color: #737272;
	}
</style>
