<template>
	<div>
		<Header title="抖音券消费详情" @back="onBack"></Header>
		<a-spin :spinning="loading">
		<div>
			<a-row style="margin: 30px 0 0;">
				<a-col :span="3">
					<img style="width: 100%;margin: 0px 0 0 10px;max-width: 180px;" :src="detail.filmImg"/>
				</a-col>
				<a-col :offset="1" :span="6">
					<p>券号：<span>{{ detail.cardNumber }}</span></p>
					<p>影院名称：<span>{{ detail.cinemaName }}</span></p>
					<p>影片长度：<span>{{ detail.duration }}分钟</span></p>
					<p>流水号：<span>{{ detail.orderNo }}</span></p>
					<p>订单状态：<span>{{ transStatus(detail.status) }}</span></p>
				</a-col>
				<a-col :offset="1" :span="6">
					<p>昵称：<span>{{ detail.nickname }}</span></p>
					<p>实际支付金额：<span>{{ detail.payment }}</span></p>
					<p>影厅名称：<span>{{ detail.hallName }}</span></p>
					<p>订单备注：<span>{{ detail.remark || '暂无' }}</span></p>
					<p>取票状态：<span>{{ transTicketStatus(detail.ticketStatus) }}</span></p>
				</a-col>
				<a-col :offset="1" :span="6">
					<p>手机号：<span>{{ detail.userPhone }}</span></p>
					<p>消费总额：<span>{{ detail.totalFee }}</span></p>
					<p>影片名称：<span>{{ detail.filmName }}</span></p>
					<p>是否退款：<span>{{ detail.status === 'AFTERSALE_FINISH' ? '是' : '否' }}</span></p>
					<p>放映时间：
						<span> {{ transDateTime(detail.showStartTime) }} 至 {{ transDateTime(detail.showEndTime) }} </span>
					</p>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="3"></a-col>
				<a-col :offset="1" :span="20">
					<p>座位：<span>
							<span v-for="(item, index) in detail.cinemaSeatsJsonVOS" :key="index">
								<a-tag style="margin-bottom: 6px;" color="blue" v-if="item">
									{{ item.rowId }}排 {{ item.colId }}座
								</a-tag>
							</span>
						</span>
					</p>
					<p style="display: flex;">券信息：
						<div>
							<div style="border: solid 1px #eee;border-radius: 8px;padding: 6px 10px;"
								v-for="item in detail.tikTokTicketOrderInfoVOS" :key="item.barcode">
								<p style="padding: 0;margin: 0;">抖音券号：{{ item.barcode }}</p>
								<p style="padding: 0;margin: 0;">抖音券名称：{{ item.title }}</p>
								<p style="padding: 0;margin: 0;">抖音商品sku：{{ item.goodsSku }}</p>
							</div>
						</div>
					</p>
				</a-col>
			</a-row>
			 
			 <div style="margin: 30px 0;text-align: center;">
				 <a-button @click="onBack">返回</a-button>
			 </div>
		</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import { getTikTokSaleDetail } from '@/service/modules/coupon.js';
	export default {
		components: {
			Icon,
			Header
		},
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data(){
			return {
				loading: false,
				detail: {}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getTikTokSaleDetail({
						orderId: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.detail = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			transTicketStatus(val) {
				if (val === 1) {
					return '未取票'
				}
				if (val === 3) {
					return '已取票'
				}
				if (val === 4) {
					return '已退票'
				}
			},
			transStatus(val, type) {
				if (val === 'WAIT_BUYER_PAY') {
					return '待支付';
				}
				if (val === 'SYSTEM_CANCEL') {
					return '系统超时取消';
				}
				if (val === 'CUSTOMER_CANCEL') {
					return '用户自行取消';
				}
				if (val === 'WAIT_SELLER_SEND_GOODS') {
					return '待发货'
				}
				if (val === 'WAIT_CHECK') {
					return '待核销'
				}
				if (val === 'TRADE_FINISH') {
					if (type === 2) {
						return '已核销'
					} else {
						return '订单完成'
					}
				}
				if (val === 'WAIT_BUYER_CONFIRM_GOODS') {
					return '待收货'
				}
				if (val === 'AFTERSALE_FINISH') {
					return '交易关闭'
				}
				if (val === 'CHECKOUT_SUCCESS') {
					return '已下单'
				}
			},
		}
	}
</script>

<style scoped>
	p > span {
		color: rgba(0,0,0,.65);
	}
</style>
